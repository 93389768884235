import { DataProxy, FetchResult } from '@apollo/client'
import { ApolloError } from 'apollo-client'
import { Dayjs } from 'dayjs'

import store from './../store'
import {
  GetLoginUserDocument,
  GetSystemStatusDocument,
  Riyosha,
  SystemStatus,
  UpdateRiyoshaDocument,
  UpdateRiyoshaInput,
  ResetRiyoshaPasswordDocument,
  ResetRiyoshaPasswordInput,
  UpdateChangePasswordDocument,
  PutOperationLogDocument,
  OperationLogMessageInput,
  GetKigyoActionSettingMstDocument,
  ListKigyoIdDocument,
  ReserveUpdateRiyoshaAttributesInput,
  ReserveUpdateRiyoshaAttributesDocument,
} from './../types/generated/graphql'
import { apolloClient } from './../vue-apollo'

import Toast, { genNotifyMessage } from '@/utils/toast'
export default {
  async mutationUpdateRiyosha(
    input: UpdateRiyoshaInput
  ): Promise<FetchResult<any, Record<string, any>, Record<string, any>>> {
    return await apolloClient.mutate({
      mutation: UpdateRiyoshaDocument,
      variables: { input },
    })
  },
  async mutationResetRiyoshaPassword(
    input: ResetRiyoshaPasswordInput
  ): Promise<FetchResult<any, Record<string, any>, Record<string, any>>> {
    return await apolloClient.mutate({
      mutation: ResetRiyoshaPasswordDocument,
      variables: { input },
    })
  },
  async mutationUpdateChangePassword(): Promise<
    FetchResult<any, Record<string, any>, Record<string, any>>
  > {
    return await apolloClient.mutate({
      mutation: UpdateChangePasswordDocument,
    })
  },
  async mutationPutOperationLog(
    input: OperationLogMessageInput
  ): Promise<FetchResult<any, Record<string, any>, Record<string, any>>> {
    return await apolloClient.mutate({
      mutation: PutOperationLogDocument,
      variables: { input },
    })
  },
  async mutationReserveUpdateRiyoshaAttributes(
    input: ReserveUpdateRiyoshaAttributesInput
  ): Promise<FetchResult<any, Record<string, any>, Record<string, any>>> {
    return await apolloClient.mutate({
      mutation: ReserveUpdateRiyoshaAttributesDocument,
      variables: { input },
    })
  },
  async getLoginUser(self: any): Promise<void> {
    return new Promise((resolve, reject) => {
      const setLoginUser = (data: any) => {
        // const { availableStores: ass, primaryStoreCd } = data.userInformation
        store.commit('app/loginUser', data.getLoginUser)
        resolve()
      }
      const errorHandler = (e: ApolloError) => {
        return e
        // Toast.error(this, {
        //   message: 'ユーザー情報が取得できませんでした。',
        // })
      }
      // 非同期的に定期的に更新
      const result = apolloClient.watchQuery<Riyosha>({
        query: GetLoginUserDocument,
        pollInterval: 1000 * 60 * 60,
      })

      result.subscribe(
        ({ data }) => setLoginUser(data),
        (e: any) => {
          errorHandler(e)
          reject(e)
        }
      )
    })
  },
  async getKigyoActionSettingMst(self: any): Promise<void> {
    return new Promise((resolve, reject) => {
      const setKigyoActionSetting = (data: any) => {
        // const { availableStores: ass, primaryStoreCd } = data.userInformation
        store.commit('app/kigyoActionSetting', data.getKigyoActionSettingMst)
        resolve()
      }
      const errorHandler = (e: ApolloError) => {
        return e
        // Toast.error(this, {
        //   message: 'ユーザー情報が取得できませんでした。',
        // })
      }
      // 非同期的に定期的に更新
      const result = apolloClient.watchQuery<Riyosha>({
        query: GetKigyoActionSettingMstDocument,
        pollInterval: 1000 * 60 * 60,
      })

      result.subscribe(
        ({ data }) => setKigyoActionSetting(data),
        (e: any) => {
          errorHandler(e)
          reject(e)
        }
      )
    })
  },
  async getSystemStatus(self: any): Promise<void> {
    return new Promise((resolve, reject) => {
      console.log('getUserSysInfo')
      const setSystemStatus = (data: any) => {
        // const { availableStores: ass, primaryStoreCd } = data.userInformation
        store.commit('app/updateSystemStatus', data.getSystemStatus)
        resolve()
      }
      const errorHandler = (e: ApolloError) => {
        return e
      }
      // 非同期的に定期的に更新
      const result = apolloClient.watchQuery<SystemStatus>({
        query: GetSystemStatusDocument,
        pollInterval: 1000 * 60 * 60,
      })

      result.subscribe(
        ({ data }) => setSystemStatus(data),
        (e: any) => {
          errorHandler(e)
          reject(e)
        }
      )
    })
  },
  async getTorihikisakiList(self: any): Promise<void> {
    return new Promise((resolve, reject) => {
      const getTorihikisakiList = (data: any) => {
        store.commit('app/torihikisakiList', data.listKigyoId.items)
        resolve()
      }
      const errorHandler = (e: ApolloError) => {
        return e
      }
      // 非同期的に定期的に更新
      const result = apolloClient.watchQuery<SystemStatus>({
        query: ListKigyoIdDocument,
        pollInterval: 1000 * 60 * 60,
      })

      result.subscribe(
        ({ data }) => getTorihikisakiList(data),
        (e: any) => {
          errorHandler(e)
          reject(e)
        }
      )
    })
  },
}
