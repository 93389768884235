import { GraphQLClient } from 'graphql-request';
import * as Dom from 'graphql-request/dist/types.dom';
import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type KeiyakuShohinJokyo = {
  __typename?: 'KeiyakuShohinJokyo';
  bunruiCd?: Maybe<Scalars['String']>;
  bunruiName?: Maybe<Scalars['String']>;
  colorCd?: Maybe<Scalars['String']>;
  colorName?: Maybe<Scalars['String']>;
  kigyoId: Scalars['String'];
  recordId: Scalars['String'];
  renban?: Maybe<Scalars['String']>;
  saishinAzukariDate?: Maybe<Scalars['String']>;
  saishinNohinDate?: Maybe<Scalars['String']>;
  shohinMstTeishiKubun?: Maybe<Scalars['String']>;
  sizeCd?: Maybe<Scalars['String']>;
  sizeName?: Maybe<Scalars['String']>;
  taiyoMstTeishiKubun?: Maybe<Scalars['String']>;
};

export type KeiyakuShohinJokyoConnection = {
  __typename?: 'KeiyakuShohinJokyoConnection';
  items?: Maybe<Array<Maybe<KeiyakuShohinJokyo>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type KigyoActionSettingMst = {
  __typename?: 'KigyoActionSettingMst';
  isEditRiyosha?: Maybe<Scalars['Boolean']>;
  isReserveRetirementRiyosha?: Maybe<Scalars['Boolean']>;
  isReserveUpdateRiyoshaAttribute?: Maybe<Scalars['Boolean']>;
  kigyoId: Scalars['String'];
};

export type KigyoIdMst = {
  __typename?: 'KigyoIdMst';
  kigyoId: Scalars['String'];
  torihikisakiCd?: Maybe<Scalars['String']>;
  torihikisakiName?: Maybe<Scalars['String']>;
};

export type KigyoIdMstConnection = {
  __typename?: 'KigyoIdMstConnection';
  items?: Maybe<Array<Maybe<KigyoIdMst>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  putOperationLog?: Maybe<PutOperationLogResult>;
  reserveUpdateRiyoshaAttributes?: Maybe<ReserveUpdateRiyoshaAttributesResult>;
  resetRiyoshaPassword?: Maybe<ResetRiyoshaPasswordResult>;
  updateChangePassword?: Maybe<UpdateChangePasswordResult>;
  updateRiyosha?: Maybe<UpdateRiyoshaResult>;
};


export type MutationPutOperationLogArgs = {
  input: OperationLogMessageInput;
};


export type MutationReserveUpdateRiyoshaAttributesArgs = {
  input?: InputMaybe<ReserveUpdateRiyoshaAttributesInput>;
};


export type MutationResetRiyoshaPasswordArgs = {
  input: ResetRiyoshaPasswordInput;
};


export type MutationUpdateRiyoshaArgs = {
  input: UpdateRiyoshaInput;
};

export type OperationLogMessageInput = {
  logType: OperationLogType;
  parameter?: InputMaybe<OperationLogParameter>;
};

export type OperationLogParameter = {
  editContent?: InputMaybe<Scalars['String']>;
  printCount?: InputMaybe<Scalars['String']>;
  printType?: InputMaybe<Scalars['String']>;
  searchCondition?: InputMaybe<Scalars['String']>;
  targetKojinCd?: InputMaybe<Scalars['String']>;
  targetKojinCdList?: InputMaybe<Scalars['String']>;
  targetLoginId?: InputMaybe<Scalars['String']>;
  targetTorihikisakiCd?: InputMaybe<Scalars['String']>;
};

export enum OperationLogType {
  EditRiyosha = 'EDIT_RIYOSHA',
  KeiyakuShohinJokyoShokaiSearch = 'KEIYAKU_SHOHIN_JOKYO_SHOKAI_SEARCH',
  LoginSuccess = 'LOGIN_SUCCESS',
  PasswordReset = 'PASSWORD_RESET',
  PrintLoginInfo = 'PRINT_LOGIN_INFO',
  ReserveRiyoshaRetirement = 'RESERVE_RIYOSHA_RETIREMENT',
  ReserveUpdateRiyoshaAttribute = 'RESERVE_UPDATE_RIYOSHA_ATTRIBUTE',
  RiyoshaSetteiSearch = 'RIYOSHA_SETTEI_SEARCH'
}

export type PutOperationLogResult = {
  __typename?: 'PutOperationLogResult';
  success?: Maybe<Scalars['Boolean']>;
};

export type Query = {
  __typename?: 'Query';
  getKigyoActionSettingMst?: Maybe<KigyoActionSettingMst>;
  getLoginUser?: Maybe<Riyosha>;
  getSystemStatus?: Maybe<SystemStatus>;
  listAllRiyosha?: Maybe<RiyoshaConnection>;
  listKeiyakuShohinJokyo?: Maybe<KeiyakuShohinJokyoConnection>;
  listKigyoId?: Maybe<KigyoIdMstConnection>;
  listReservedUpdateRiyosha?: Maybe<ReservedUpdateRiyoshaConnection>;
};


export type QueryListAllRiyoshaArgs = {
  count?: InputMaybe<Scalars['Int']>;
  isChangePassword?: InputMaybe<Scalars['String']>;
  isLoginNotPossible?: InputMaybe<Scalars['Boolean']>;
  isLoginPossible?: InputMaybe<Scalars['Boolean']>;
  kojinCd?: InputMaybe<Scalars['String']>;
  loginId?: InputMaybe<Scalars['String']>;
  loginIdNotSet?: InputMaybe<Scalars['Boolean']>;
  meishoKanji?: InputMaybe<Scalars['String']>;
  nextToken?: InputMaybe<Scalars['String']>;
};


export type QueryListKeiyakuShohinJokyoArgs = {
  count?: InputMaybe<Scalars['Int']>;
  kojinCd: Scalars['String'];
  nextToken?: InputMaybe<Scalars['String']>;
  torihikisakiCd: Scalars['String'];
};


export type QueryListReservedUpdateRiyoshaArgs = {
  kojinCd: Scalars['String'];
  torihikisakiCd: Scalars['String'];
};

export type ReserveUpdateRiyoshaAttributesInput = {
  applyDate: Scalars['String'];
  kojinCd: Scalars['String'];
  meishoKanaNew?: InputMaybe<Scalars['String']>;
  meishoKanaOrg?: InputMaybe<Scalars['String']>;
  meishoKanjiNew?: InputMaybe<Scalars['String']>;
  meishoKanjiOrg?: InputMaybe<Scalars['String']>;
  operation: Scalars['String'];
  torihikisakiCd: Scalars['String'];
};

export type ReserveUpdateRiyoshaAttributesResult = {
  __typename?: 'ReserveUpdateRiyoshaAttributesResult';
  ReservedUpdateRiyosha?: Maybe<ReservedUpdateRiyosha>;
  message?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type ReservedUpdateRiyosha = {
  __typename?: 'ReservedUpdateRiyosha';
  applyDate?: Maybe<Scalars['String']>;
  meishoKanaNew?: Maybe<Scalars['String']>;
  meishoKanaOrg?: Maybe<Scalars['String']>;
  meishoKanjiNew?: Maybe<Scalars['String']>;
  meishoKanjiOrg?: Maybe<Scalars['String']>;
  notificationTimestamp?: Maybe<Scalars['String']>;
  operation?: Maybe<Scalars['String']>;
  saishuKoushinTimestamp?: Maybe<Scalars['String']>;
  saishuKoushinUser?: Maybe<Scalars['String']>;
  taishoKey?: Maybe<Scalars['String']>;
};

export type ReservedUpdateRiyoshaConnection = {
  __typename?: 'ReservedUpdateRiyoshaConnection';
  items?: Maybe<Array<Maybe<ReservedUpdateRiyosha>>>;
};

export type ResetRiyoshaPasswordInput = {
  initialPassword: Scalars['String'];
  kojinCd: Scalars['String'];
  loginId: Scalars['String'];
  torihikisakiCd: Scalars['String'];
};

export type ResetRiyoshaPasswordResult = {
  __typename?: 'ResetRiyoshaPasswordResult';
  message?: Maybe<Scalars['String']>;
  riyosha?: Maybe<Riyosha>;
  success: Scalars['Boolean'];
};

export type Riyosha = {
  __typename?: 'Riyosha';
  bushoCd?: Maybe<Scalars['String']>;
  bushoName?: Maybe<Scalars['String']>;
  initialPassword?: Maybe<Scalars['String']>;
  isChangePassword?: Maybe<Scalars['String']>;
  isLoginNotPossible?: Maybe<Scalars['Boolean']>;
  kigyoId: Scalars['String'];
  kigyoIdIndex: Scalars['String'];
  kojinCd: Scalars['String'];
  loginId?: Maybe<Scalars['String']>;
  meishoKana?: Maybe<Scalars['String']>;
  meishoKanji?: Maybe<Scalars['String']>;
  nohinbasho?: Maybe<Scalars['String']>;
  teishiKubun?: Maybe<Scalars['String']>;
  torihikisakiCd: Scalars['String'];
  userTypes?: Maybe<Scalars['String']>;
  userpoolEnable?: Maybe<Scalars['Boolean']>;
  userpoolStatus?: Maybe<Scalars['String']>;
};

export type RiyoshaConnection = {
  __typename?: 'RiyoshaConnection';
  items?: Maybe<Array<Maybe<Riyosha>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type SystemStatus = {
  __typename?: 'SystemStatus';
  isMaintenance?: Maybe<Scalars['Boolean']>;
  kigyoId: Scalars['String'];
};

export type UpdateChangePasswordResult = {
  __typename?: 'UpdateChangePasswordResult';
  message?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type UpdateRiyoshaInput = {
  initialPassword: Scalars['String'];
  isLoginNotPossible: Scalars['Boolean'];
  kojinCd: Scalars['String'];
  loginId: Scalars['String'];
  torihikisakiCd: Scalars['String'];
};

export type UpdateRiyoshaResult = {
  __typename?: 'UpdateRiyoshaResult';
  message?: Maybe<Scalars['String']>;
  riyosha?: Maybe<Riyosha>;
  success: Scalars['Boolean'];
};

export type PutOperationLogMutationVariables = Exact<{
  input: OperationLogMessageInput;
}>;


export type PutOperationLogMutation = { __typename?: 'Mutation', putOperationLog?: { __typename?: 'PutOperationLogResult', success?: boolean | null } | null };

export type ReserveUpdateRiyoshaAttributesMutationVariables = Exact<{
  input?: InputMaybe<ReserveUpdateRiyoshaAttributesInput>;
}>;


export type ReserveUpdateRiyoshaAttributesMutation = { __typename?: 'Mutation', reserveUpdateRiyoshaAttributes?: { __typename?: 'ReserveUpdateRiyoshaAttributesResult', message?: string | null, success: boolean, ReservedUpdateRiyosha?: { __typename?: 'ReservedUpdateRiyosha', applyDate?: string | null, meishoKanaNew?: string | null, meishoKanaOrg?: string | null, meishoKanjiNew?: string | null, meishoKanjiOrg?: string | null, notificationTimestamp?: string | null, operation?: string | null, taishoKey?: string | null } | null } | null };

export type ResetRiyoshaPasswordMutationVariables = Exact<{
  input: ResetRiyoshaPasswordInput;
}>;


export type ResetRiyoshaPasswordMutation = { __typename?: 'Mutation', resetRiyoshaPassword?: { __typename?: 'ResetRiyoshaPasswordResult', success: boolean, message?: string | null, riyosha?: { __typename?: 'Riyosha', kigyoId: string, kojinCd: string, kigyoIdIndex: string, torihikisakiCd: string, meishoKanji?: string | null, meishoKana?: string | null, bushoCd?: string | null, bushoName?: string | null, nohinbasho?: string | null, teishiKubun?: string | null, loginId?: string | null, userTypes?: string | null, userpoolStatus?: string | null, userpoolEnable?: boolean | null, isLoginNotPossible?: boolean | null, isChangePassword?: string | null, initialPassword?: string | null } | null } | null };

export type UpdateChangePasswordMutationVariables = Exact<{ [key: string]: never; }>;


export type UpdateChangePasswordMutation = { __typename?: 'Mutation', updateChangePassword?: { __typename?: 'UpdateChangePasswordResult', success: boolean, message?: string | null } | null };

export type UpdateRiyoshaMutationVariables = Exact<{
  input: UpdateRiyoshaInput;
}>;


export type UpdateRiyoshaMutation = { __typename?: 'Mutation', updateRiyosha?: { __typename?: 'UpdateRiyoshaResult', success: boolean, message?: string | null, riyosha?: { __typename?: 'Riyosha', kigyoId: string, kojinCd: string, kigyoIdIndex: string, torihikisakiCd: string, meishoKanji?: string | null, meishoKana?: string | null, bushoCd?: string | null, bushoName?: string | null, nohinbasho?: string | null, teishiKubun?: string | null, loginId?: string | null, userTypes?: string | null, userpoolStatus?: string | null, userpoolEnable?: boolean | null, isLoginNotPossible?: boolean | null, isChangePassword?: string | null, initialPassword?: string | null } | null } | null };

export type GetKigyoActionSettingMstQueryVariables = Exact<{ [key: string]: never; }>;


export type GetKigyoActionSettingMstQuery = { __typename?: 'Query', getKigyoActionSettingMst?: { __typename?: 'KigyoActionSettingMst', isEditRiyosha?: boolean | null, isReserveUpdateRiyoshaAttribute?: boolean | null, isReserveRetirementRiyosha?: boolean | null } | null };

export type GetLoginUserQueryVariables = Exact<{ [key: string]: never; }>;


export type GetLoginUserQuery = { __typename?: 'Query', getLoginUser?: { __typename?: 'Riyosha', kigyoId: string, kojinCd: string, kigyoIdIndex: string, meishoKanji?: string | null, meishoKana?: string | null, bushoCd?: string | null, bushoName?: string | null, nohinbasho?: string | null, teishiKubun?: string | null, torihikisakiCd: string, loginId?: string | null, userTypes?: string | null } | null };

export type GetSystemStatusQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSystemStatusQuery = { __typename?: 'Query', getSystemStatus?: { __typename?: 'SystemStatus', kigyoId: string, isMaintenance?: boolean | null } | null };

export type ListAllRiyoshaQueryVariables = Exact<{
  kojinCd?: InputMaybe<Scalars['String']>;
  meishoKanji?: InputMaybe<Scalars['String']>;
  loginId?: InputMaybe<Scalars['String']>;
  loginIdNotSet?: InputMaybe<Scalars['Boolean']>;
  isChangePassword?: InputMaybe<Scalars['String']>;
  isLoginPossible?: InputMaybe<Scalars['Boolean']>;
  isLoginNotPossible?: InputMaybe<Scalars['Boolean']>;
  nextToken?: InputMaybe<Scalars['String']>;
  count?: InputMaybe<Scalars['Int']>;
}>;


export type ListAllRiyoshaQuery = { __typename?: 'Query', listAllRiyosha?: { __typename?: 'RiyoshaConnection', nextToken?: string | null, items?: Array<{ __typename?: 'Riyosha', kigyoId: string, kojinCd: string, kigyoIdIndex: string, torihikisakiCd: string, meishoKanji?: string | null, meishoKana?: string | null, bushoCd?: string | null, bushoName?: string | null, nohinbasho?: string | null, teishiKubun?: string | null, loginId?: string | null, userTypes?: string | null, userpoolStatus?: string | null, userpoolEnable?: boolean | null, isLoginNotPossible?: boolean | null, isChangePassword?: string | null, initialPassword?: string | null } | null> | null } | null };

export type ListKeiyakuShohinJokyoQueryVariables = Exact<{
  torihikisakiCd: Scalars['String'];
  kojinCd: Scalars['String'];
  nextToken?: InputMaybe<Scalars['String']>;
  count?: InputMaybe<Scalars['Int']>;
}>;


export type ListKeiyakuShohinJokyoQuery = { __typename?: 'Query', listKeiyakuShohinJokyo?: { __typename?: 'KeiyakuShohinJokyoConnection', nextToken?: string | null, items?: Array<{ __typename?: 'KeiyakuShohinJokyo', kigyoId: string, recordId: string, renban?: string | null, bunruiCd?: string | null, bunruiName?: string | null, sizeCd?: string | null, sizeName?: string | null, colorCd?: string | null, colorName?: string | null, saishinAzukariDate?: string | null, saishinNohinDate?: string | null, taiyoMstTeishiKubun?: string | null, shohinMstTeishiKubun?: string | null } | null> | null } | null };

export type ListKeiyakuShohinJokyoWithListReservedUpdateRiyoshaQueryVariables = Exact<{
  torihikisakiCd: Scalars['String'];
  kojinCd: Scalars['String'];
  nextToken?: InputMaybe<Scalars['String']>;
  count?: InputMaybe<Scalars['Int']>;
}>;


export type ListKeiyakuShohinJokyoWithListReservedUpdateRiyoshaQuery = { __typename?: 'Query', listKeiyakuShohinJokyo?: { __typename?: 'KeiyakuShohinJokyoConnection', nextToken?: string | null, items?: Array<{ __typename?: 'KeiyakuShohinJokyo', kigyoId: string, recordId: string, renban?: string | null, bunruiCd?: string | null, bunruiName?: string | null, sizeCd?: string | null, sizeName?: string | null, colorCd?: string | null, colorName?: string | null, saishinAzukariDate?: string | null, saishinNohinDate?: string | null, taiyoMstTeishiKubun?: string | null, shohinMstTeishiKubun?: string | null } | null> | null } | null, listReservedUpdateRiyosha?: { __typename?: 'ReservedUpdateRiyoshaConnection', items?: Array<{ __typename?: 'ReservedUpdateRiyosha', applyDate?: string | null, meishoKanaNew?: string | null, meishoKanaOrg?: string | null, meishoKanjiNew?: string | null, meishoKanjiOrg?: string | null, notificationTimestamp?: string | null, operation?: string | null, saishuKoushinTimestamp?: string | null, saishuKoushinUser?: string | null, taishoKey?: string | null } | null> | null } | null };

export type ListKigyoIdQueryVariables = Exact<{ [key: string]: never; }>;


export type ListKigyoIdQuery = { __typename?: 'Query', listKigyoId?: { __typename?: 'KigyoIdMstConnection', nextToken?: string | null, items?: Array<{ __typename?: 'KigyoIdMst', torihikisakiCd?: string | null, torihikisakiName?: string | null } | null> | null } | null };


export const PutOperationLogDocument = gql`
    mutation putOperationLog($input: OperationLogMessageInput!) {
  putOperationLog(input: $input) {
    success
  }
}
    `;
export const ReserveUpdateRiyoshaAttributesDocument = gql`
    mutation reserveUpdateRiyoshaAttributes($input: ReserveUpdateRiyoshaAttributesInput) {
  reserveUpdateRiyoshaAttributes(input: $input) {
    ReservedUpdateRiyosha {
      applyDate
      meishoKanaNew
      meishoKanaOrg
      meishoKanjiNew
      meishoKanjiOrg
      notificationTimestamp
      operation
      taishoKey
    }
    message
    success
  }
}
    `;
export const ResetRiyoshaPasswordDocument = gql`
    mutation resetRiyoshaPassword($input: ResetRiyoshaPasswordInput!) {
  resetRiyoshaPassword(input: $input) {
    success
    message
    riyosha {
      kigyoId
      kojinCd
      kigyoIdIndex
      torihikisakiCd
      meishoKanji
      meishoKana
      bushoCd
      bushoName
      nohinbasho
      teishiKubun
      loginId
      userTypes
      userpoolStatus
      userpoolEnable
      isLoginNotPossible
      isChangePassword
      initialPassword
    }
  }
}
    `;
export const UpdateChangePasswordDocument = gql`
    mutation updateChangePassword {
  updateChangePassword {
    success
    message
  }
}
    `;
export const UpdateRiyoshaDocument = gql`
    mutation updateRiyosha($input: UpdateRiyoshaInput!) {
  updateRiyosha(input: $input) {
    success
    message
    riyosha {
      kigyoId
      kojinCd
      kigyoIdIndex
      torihikisakiCd
      meishoKanji
      meishoKana
      bushoCd
      bushoName
      nohinbasho
      teishiKubun
      loginId
      userTypes
      userpoolStatus
      userpoolEnable
      isLoginNotPossible
      isChangePassword
      initialPassword
    }
  }
}
    `;
export const GetKigyoActionSettingMstDocument = gql`
    query getKigyoActionSettingMst {
  getKigyoActionSettingMst {
    isEditRiyosha
    isReserveUpdateRiyoshaAttribute
    isReserveRetirementRiyosha
  }
}
    `;
export const GetLoginUserDocument = gql`
    query getLoginUser {
  getLoginUser {
    kigyoId
    kojinCd
    kigyoIdIndex
    meishoKanji
    meishoKana
    bushoCd
    bushoName
    nohinbasho
    teishiKubun
    torihikisakiCd
    loginId
    userTypes
  }
}
    `;
export const GetSystemStatusDocument = gql`
    query getSystemStatus {
  getSystemStatus {
    kigyoId
    isMaintenance
  }
}
    `;
export const ListAllRiyoshaDocument = gql`
    query listAllRiyosha($kojinCd: String, $meishoKanji: String, $loginId: String, $loginIdNotSet: Boolean, $isChangePassword: String, $isLoginPossible: Boolean, $isLoginNotPossible: Boolean, $nextToken: String, $count: Int) {
  listAllRiyosha(
    kojinCd: $kojinCd
    meishoKanji: $meishoKanji
    loginId: $loginId
    loginIdNotSet: $loginIdNotSet
    isChangePassword: $isChangePassword
    isLoginPossible: $isLoginPossible
    isLoginNotPossible: $isLoginNotPossible
    nextToken: $nextToken
    count: $count
  ) {
    items {
      kigyoId
      kojinCd
      kigyoIdIndex
      torihikisakiCd
      meishoKanji
      meishoKana
      bushoCd
      bushoName
      nohinbasho
      teishiKubun
      loginId
      userTypes
      userpoolStatus
      userpoolEnable
      isLoginNotPossible
      isChangePassword
      initialPassword
    }
    nextToken
  }
}
    `;
export const ListKeiyakuShohinJokyoDocument = gql`
    query listKeiyakuShohinJokyo($torihikisakiCd: String!, $kojinCd: String!, $nextToken: String, $count: Int) {
  listKeiyakuShohinJokyo(
    torihikisakiCd: $torihikisakiCd
    kojinCd: $kojinCd
    nextToken: $nextToken
    count: $count
  ) {
    items {
      kigyoId
      recordId
      renban
      bunruiCd
      bunruiName
      sizeCd
      sizeName
      colorCd
      colorName
      saishinAzukariDate
      saishinNohinDate
      taiyoMstTeishiKubun
      shohinMstTeishiKubun
    }
    nextToken
  }
}
    `;
export const ListKeiyakuShohinJokyoWithListReservedUpdateRiyoshaDocument = gql`
    query listKeiyakuShohinJokyoWithListReservedUpdateRiyosha($torihikisakiCd: String!, $kojinCd: String!, $nextToken: String, $count: Int) {
  listKeiyakuShohinJokyo(
    torihikisakiCd: $torihikisakiCd
    kojinCd: $kojinCd
    nextToken: $nextToken
    count: $count
  ) {
    items {
      kigyoId
      recordId
      renban
      bunruiCd
      bunruiName
      sizeCd
      sizeName
      colorCd
      colorName
      saishinAzukariDate
      saishinNohinDate
      taiyoMstTeishiKubun
      shohinMstTeishiKubun
    }
    nextToken
  }
  listReservedUpdateRiyosha(kojinCd: $kojinCd, torihikisakiCd: $torihikisakiCd) {
    items {
      applyDate
      meishoKanaNew
      meishoKanaOrg
      meishoKanjiNew
      meishoKanjiOrg
      notificationTimestamp
      operation
      saishuKoushinTimestamp
      saishuKoushinUser
      taishoKey
    }
  }
}
    `;
export const ListKigyoIdDocument = gql`
    query listKigyoId {
  listKigyoId {
    items {
      torihikisakiCd
      torihikisakiName
    }
    nextToken
  }
}
    `;

export type SdkFunctionWrapper = <T>(action: (requestHeaders?:Record<string, string>) => Promise<T>, operationName: string) => Promise<T>;


const defaultWrapper: SdkFunctionWrapper = (action, _operationName) => action();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    putOperationLog(variables: PutOperationLogMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<PutOperationLogMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<PutOperationLogMutation>(PutOperationLogDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'putOperationLog');
    },
    reserveUpdateRiyoshaAttributes(variables?: ReserveUpdateRiyoshaAttributesMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ReserveUpdateRiyoshaAttributesMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<ReserveUpdateRiyoshaAttributesMutation>(ReserveUpdateRiyoshaAttributesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'reserveUpdateRiyoshaAttributes');
    },
    resetRiyoshaPassword(variables: ResetRiyoshaPasswordMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ResetRiyoshaPasswordMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<ResetRiyoshaPasswordMutation>(ResetRiyoshaPasswordDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'resetRiyoshaPassword');
    },
    updateChangePassword(variables?: UpdateChangePasswordMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdateChangePasswordMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateChangePasswordMutation>(UpdateChangePasswordDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateChangePassword');
    },
    updateRiyosha(variables: UpdateRiyoshaMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdateRiyoshaMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateRiyoshaMutation>(UpdateRiyoshaDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateRiyosha');
    },
    getKigyoActionSettingMst(variables?: GetKigyoActionSettingMstQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetKigyoActionSettingMstQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetKigyoActionSettingMstQuery>(GetKigyoActionSettingMstDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getKigyoActionSettingMst');
    },
    getLoginUser(variables?: GetLoginUserQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetLoginUserQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetLoginUserQuery>(GetLoginUserDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getLoginUser');
    },
    getSystemStatus(variables?: GetSystemStatusQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetSystemStatusQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetSystemStatusQuery>(GetSystemStatusDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getSystemStatus');
    },
    listAllRiyosha(variables?: ListAllRiyoshaQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ListAllRiyoshaQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<ListAllRiyoshaQuery>(ListAllRiyoshaDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'listAllRiyosha');
    },
    listKeiyakuShohinJokyo(variables: ListKeiyakuShohinJokyoQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ListKeiyakuShohinJokyoQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<ListKeiyakuShohinJokyoQuery>(ListKeiyakuShohinJokyoDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'listKeiyakuShohinJokyo');
    },
    listKeiyakuShohinJokyoWithListReservedUpdateRiyosha(variables: ListKeiyakuShohinJokyoWithListReservedUpdateRiyoshaQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ListKeiyakuShohinJokyoWithListReservedUpdateRiyoshaQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<ListKeiyakuShohinJokyoWithListReservedUpdateRiyoshaQuery>(ListKeiyakuShohinJokyoWithListReservedUpdateRiyoshaDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'listKeiyakuShohinJokyoWithListReservedUpdateRiyosha');
    },
    listKigyoId(variables?: ListKigyoIdQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ListKigyoIdQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<ListKigyoIdQuery>(ListKigyoIdDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'listKigyoId');
    }
  };
}
export type Sdk = ReturnType<typeof getSdk>;